body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.SVG--highlighted {
    &.active,
    &:hover {
        fill: #73859f;
        cursor: pointer;
    }

    &.house-flat_empty {
        cursor: not-allowed;
    }
}

.SVG--123 {
    background-color: #000;
}

.SVG__circle--highlighted {
    &:hover {
        fill: #ef9a9a;
        cursor: pointer;
    }
}

.SVG__circle-general--highlighted {
    &:hover {
        fill: #3f51b5;
        cursor: pointer;
    }
}

.selectable-selectbox {
    z-index: 9000;
    position: absolute;
    cursor: default;
    background: none;
    border: 1px dashed grey;
}

.text-crossed {
    text-decoration: line-through;
}

/*#root {
    .MuiFab-primary, .MuiButton-containedPrimary {
        color: #fff;
        background-color: #37485C;
    }

    .MuiSvgIcon-colorPrimary {
        color: #37485C;
    }

    .MuiButton-outlinedPrimary {
        color: #37485C;
        border: 1px solid rgba(55, 72, 92, 0.5);
    }
}*/
